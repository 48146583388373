* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Styles for Sticky (But Not Fixed) Footer */
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* ========================= */

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  /* font-family: 'Oxygen', sans-serif; */
  font-family: 'EB Garamond', serif;
  letter-spacing: 1px;
  font-size: 18px;
  color: #26231e;
  position: relative;
  margin: 0;
  /* padding-bottom: 6rem; */
  min-height: 100%;
}

/* Landing Page Styles */
#landing-header {
  align-items: center;
  display: flex;
  font-size: 18px;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform-style: preserve-3d;
  perspective: 100px;
}

#landing-msg {
  /* border: 1px solid #ffffff; */
  border: 2px solid #26231e;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #26231e;
}

#landing {
  font-family: 'Quicksand', sans-serif;
  color: #26231e;
}

#landing-msg:hover {
  /* background: rgba(255, 255, 255, 0.5); */
  background: #26231e;
}

#landing:hover {
  color: #ffeeff;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.7rem 2rem; */
  z-index: 1;
  width: 100%;
  background-color: #ff6347 !important;
  /* border-bottom: solid 1px var(--primary-color); */
  margin-bottom: 1rem;
}

.nav-title {
  font-family: 'Quicksand', sans-serif;
  color: #ffeeff;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1.5px;
  padding-bottom: 0.5em;
  /* text-transform: uppercase; */
  margin-top: 2.5px;
}

/* .navbar .welcome span {
  margin-right: 0.6rem;
} */

#links {
  color: #c7d1d8 !important;
  font-family: 'Quicksand', sans-serif;
}

#links {
  margin-bottom: -15px;
  float: inherit;
}

.navbar ul {
  display: flex;
}

.navbar li {
  list-style: none;
}

.navbar a {
  /* color: #ffffff; */
  color: #dedede;
  font-size: 18px;
  margin: 0 0.25rem;
  margin-bottom: -30px;
}

.navbar a:hover {
  /* color: var(--light-color); */
  /* color: #edc19f; */
  color: #ffffff;
}

.navbar .welcome span {
  margin-right: 0.6rem;
}
/* End Navbar */

.card-title {
  font-family: 'Quicksand', sans-serif;
}

/* Prism Line Number Plugin */
pre[data-number] {
  position: relative;
  padding: 1em 0 1em 3em;
}

.line-number {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em; /* Same as .prism's padding-top */
  background: transparent;
  pointer-events: none;
  line-height: inherit;
  white-space: pre;
}

.line-number:before,
.line-number[data-end]:after {
  content: attr(data-start);
  position: absolute;
  top: 0.4em;
  left: 0.6em;
  min-width: 1em;
  padding: 0 0.5em;
  color: #999;
  font: bold 65%/1.5 sans-serif;
  text-align: center;
  vertical-align: 0.3em;
  border-radius: 999px;
  text-shadow: none;
  border: 0;
}

.line-number[data-end]:after {
  content: attr(data-end);
  top: auto;
  bottom: 0.4em;
}
/* End Prism Line Number Plugin */

h3 {
  font-family: 'Quicksand', sans-serif;
}

.content-div {
  margin-bottom: 80px;
}

.click {
  color: #3e63bd;
}

a {
  /* color: #007bff; */
  color: #ffeeff;
  text-decoration: none;
  background-color: transparent;
  padding: 0 10px;
}

a:hover {
  color: #ff6347;
  text-decoration: none;
}

#app-logo {
  width: 40px;
  margin-bottom: 4px;
}

#back-div {
  text-align: center;
}

#error-msg,
#success-msg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#guides-btn,
#notes-btn,
#error-btn,
#submit-btn,
#back-btn {
  font-family: 'Quicksand', sans-serif;
  background: #ff6347;
  border-color: #ff6347;
  margin-right: 5px;
  margin-bottom: 40px;
}

#disclaimer {
  font-size: 0.75rem;
}

/* Sticky (But Not Fixed) Footer,
Source: https://chrisbracco.com/css-sticky-footer-effect/ */
.footer {
  position: absolute;
  right: 0;
  bottom: -5px;
  /* bottom: 0; */
  left: 0;
  padding: 12px;
  background: #ff6347;
  text-align: center;
}
/* ========================= */

#footer-text {
  font-family: 'Quicksand', sans-serif;
  color: #ffeeff;
  letter-spacing: 1.5px;
  font-size: 16px;
  margin-top: 5px;
}
/* End Footer */

/* ===== MEDIA QUERIES ===== */

@media screen and (min-width: 981px) and (max-width: 1200px) {
  /* body {
    background-color: red;
  } */

  .nav-title {
    padding-bottom: 0.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 980px) {
  /* body {
    background-color: yellow;
  } */

  .nav-title {
    padding-bottom: 0.5em;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  /* body {
    background-color: steelblue;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 551px) and (max-width: 640px) {
  /* body {
    background-color: brown;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 471px) and (max-width: 550px) {
  /* body {
    background-color: salmon;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 431px) and (max-width: 470px) {
  /* body {
    background-color: black;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  #brand-link {
    float: inline-end;
    margin-top: 5px;
    margin-bottom: -60px;
    font-size: 28px;
  }

  #brand-link {
    float: inline-end;
    font-size: 28px;
  }
}

@media screen and (min-width: 401px) and (max-width: 430px) {
  /* body {
    background-color: burlywood;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  #brand-link {
    float: inline-end;
    margin-top: 5px;
    margin-bottom: -60px;
    font-size: 28px;
  }
  /* 
  .navbar a {
    font-size: 18px;
  } */

  /* .nav-link {
    font-size: 14px;
  } */
}

@media screen and (min-width: 100px) and (max-width: 400px) {
  /* body {
    background-color: pink;
  } */

  .navbar {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }

  .nav-title {
    margin-top: -20px;
    margin-bottom: -30px;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  #brand-link {
    float: inline-end;
    margin-top: 12px;
    margin-bottom: -60px;
    font-size: 18px;
  }

  .navbar a {
    font-size: 12.5px;
  }

  #guides-btn {
    margin-bottom: 10px;
  }

  #notes-btn {
    margin-bottom: 40px;
  }
}
